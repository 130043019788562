function initYandexMap() {
	ymaps.ready(function () {

		var $balloon = 'img/icons/icon-balloon.svg';

		var myMap = new ymaps.Map('map', {
			center: [54.933864, 83.189286],
			zoom: 13,
			controls: ['zoomControl']
		}, {
			searchControlProvider: 'yandex#search'
		});

		myMap.behaviors.disable('scrollZoom');

		var $mainLocation = myMap.geoObjects.add(new ymaps.Placemark([54.933864, 83.189286], {}, {
			iconLayout: 'default#image',
			iconImageHref: $balloon,
			iconImageSize: [32, 40],
		}));
	});
}

$(document).ready(function () {

	// PAGE HEADER & MOBILE MENU

	var windowWidth = $(window).outerWidth();

	var docElements = $('html, body'),
		docWrapper = $('.page__inner'),
		scrollTop;

	function getScrollbarWidth() {
		return window.innerWidth - document.documentElement.clientWidth;
	}

	$.lockBody = function () {

		if (window.pageYOffset) {
			scrollTop = window.pageYOffset;

			docWrapper.css({
				top: -(scrollTop)
			});
		}

		docElements.css({
			height: "100%",
			overflow: "hidden"
		});

		//$('.page-header').removeAttr('style');
	};

	$.unlockBody = function () {

		docElements.css({
			height: "",
			overflow: ""
		});

		docWrapper.css({
			top: ''
		});

		window.scrollTo(0, scrollTop);

		window.setTimeout(function () {
			scrollTop = null;
		}, 0);

	};

	$('.js-menu-toggle').on('click', function () {
		var $this = $(this),
			$pageHeaderNav = $('.page-header__nav');

		$this.toggleClass('is-active');

		if ($this.hasClass('is-active')) {
			$pageHeaderNav.addClass('is-opened');
			$pageHeaderNav.fadeIn(300, function () {
				if (windowWidth < 768) {
					$.lockBody();
				}
			});

		} else {
			if (windowWidth < 768) {
				$.unlockBody();
			}
			$pageHeaderNav.removeClass('is-opened');
			$pageHeaderNav.fadeOut(300);
		}
	});

	$(window).on('scroll', function () {

		var $this = $(this);
		var $scroll = $(window).scrollTop();
		var $windowHalfHeight = ($(window).height());
		var $pageHeader = $('.page-header');
		var $pageHeaderHeight = $('.page-header').height();

		/*todo: протестировать на 1024!!!*/

/*		if ($scroll > $pageHeaderHeight && !$pageHeader.hasClass('invisible')) {
			$pageHeader.addClass('invisible');
			$pageHeader.hide();
			docWrapper.css('padding-top', $pageHeaderHeight + 'px');
		} else if ($scroll <= $pageHeaderHeight && !$('.page-header__nav').hasClass('is-opened')) {
			$pageHeader.fadeIn(300);
			docWrapper.removeAttr('style');
			$pageHeader.removeClass('invisible');
		}

		if ($scroll >= $windowHalfHeight) {
			$pageHeader.addClass('page-header--fixed');
			$pageHeader.fadeIn(300);
		} else if ($scroll <= $windowHalfHeight && $pageHeader.hasClass('page-header--fixed') && !$('.page-header__nav').hasClass('is-opened')) {
			$pageHeader.fadeOut(300);
			$pageHeader.removeClass('page-header--fixed');
		}*/

	});

	// SLIDERS

	function sliderUpadate() {

		if ($(".swiper-container").length) {

			$(".swiper-container").each(function (index, element) {
				var $swiper = this.swiper;
				if ($swiper !== undefined) {
					$swiper.update();
				}
			});
		}

	}

	// Structure slider

	var structureSlider;
	var structureSliderParams = {
		direction: 'horizontal',
		spaceBetween: 0,
		slidesPerView: 1,
		watchOverflow: true,
		navigation: {
			prevEl: '.js-structure-slider-btn-prev',
			nextEl: '.js-structure-slider-btn-next',
		}
	};

	function structureSliderInit() {

		if ($('.js-structure-slider').length) {
			var $windowWidth = $(window).outerWidth();

			if (($windowWidth >= 768) && (structureSlider !== undefined)) {
				structureSlider.destroy(true, true);
				structureSlider = undefined;
				return;
			}

			if ($windowWidth < 768) {
				structureSlider = new Swiper('.js-structure-slider', structureSliderParams);
			}

		}
	}

	structureSliderInit();

	// History slider

	var historySlider;

	function historySlider() {

		if ($('.js-history-slider').length) {

			var historySliderParams = {
				direction: 'horizontal',
				spaceBetween: 0,
				grabCursor: true,
				slidesPerView: 1,
				watchOverflow: true,
				navigation: {
					prevEl: '.js-history-slider-btn-prev',
					nextEl: '.js-history-slider-btn-next',
				},
				breakpoints: {
					768: {
						slidesPerView: 3
					},
					/*					1024: {
											slidesPerView: 3
										},*/
					1280: {
						slidesPerView: 4
					},
					1700: {
						slidesPerView: 5
					}
				}
			};

			historySlider = new Swiper('.js-history-slider', historySliderParams);
		}
	}

	historySlider();

	// Logo slider

	var logoSliderInstances = [];

	function logoSlider() {

		if ($('.js-logo-slider').length) {

			$(".js-logo-slider").each(function (index, element) {
				var $this = $(this);
				$this.addClass("instance-" + index);

				logoSliderInstances[index] = new Swiper($this, {
					direction: 'horizontal',
					spaceBetween: 0,
					slidesPerView: 1,
					loop: true,
					watchOverflow: true,
					breakpoints: {
						768: {
							slidesPerView: 5,
							spaceBetween: 30
						},
						1024: {
							slidesPerView: 5,
							spaceBetween: 40
						},
						1280: {
							slidesPerView: 5,
							spaceBetween: 60
						},
						1440: {
							slidesPerView: 6,
							spaceBetween: 60
						},
						1700: {
							slidesPerView: 7,
							spaceBetween: 75
						}
					},
					navigation: {
						prevEl: $this.parent().find(".swiper-button-prev")[0],
						nextEl: $this.parent().find(".swiper-button-next")[0]
					}
				});
			});
		}
	}

	logoSlider();

	// Reviews slider

	var reviewsSliderInstances = [];

	function reviewsSliderInit() {

		if ($('.js-reviews-slider').length) {

			$(".js-reviews-slider").each(function (index, element) {
				var $this = $(this);
				reviewsSliderInstances[index] = new Swiper($this, {
					direction: 'horizontal',
					spaceBetween: 0,
					grabCursor: true,
					slidesPerView: 1,
					watchOverflow: true,
					pagination: {
						el: $this.closest('section').find(".swiper-pagination")[0],
						type: 'bullets',
						clickable: true,
						hideOnClick: false
					}
				});
			});
		}
	}

	reviewsSliderInit();

	// Mobile slider

	var mobileSliderInstances = [];

	function mobileSliderDestroy() {

		$(".js-mobile-slider").each(function (index, element) {
			var $swiper = this.swiper;

			if ($swiper !== undefined) {
				$swiper.destroy(true, true);
				$swiper[index] = undefined;
			}
		});
	}

	function mobileSliderInit() {

		if ($('.js-mobile-slider').length) {
			var $windowWidth = $(window).outerWidth();

			if ($windowWidth >= 768) {
				mobileSliderDestroy();
			}

			if ($windowWidth < 768) {

				$(".js-mobile-slider").each(function (index, element) {
					var $this = $(this);
					$this.addClass("instance-" + index);
					mobileSliderInstances[index] = new Swiper($this, {
						direction: 'horizontal',
						spaceBetween: 0,
						slidesPerView: 1,
						loop: true,
						watchOverflow: true,
						navigation: {
							prevEl: $this.parent().find(".swiper-button-prev")[0],
							nextEl: $this.parent().find(".swiper-button-next")[0]
						}
					});
				});

			}
		}
	}

	mobileSliderInit();

	// Dual slider

	var interleaveOffset = 0.5;

	if ($('.dual-slider').length > 0) {

		var dualImageInstances = [];
		var dualTextInstances = [];
		var dualSliderImageParams;

		$(".js-dual-slider-image").each(function (index, element) {
			var $this = $(this);

			if ($this.hasClass('dual-slider-image--logo')) {
				dualSliderImageParams = {
					direction: 'horizontal',
					spaceBetween: 0,
					speed: 1500,
					loop: true,
					watchSlidesProgress: true,
					slidesPerView: 1,
					effect: 'flip',
					flipEffect: {
						rotate: 30,
						slideShadows: false,
					},
					controller: {
						by: 'slide',
					},
				};
			} else {
				dualSliderImageParams = {
					grabCursor: true,
					direction: 'horizontal',
					spaceBetween: 0,
					speed: 1500,
					loop: true,
					watchSlidesProgress: true,
					slidesPerView: 1,
					controller: {
						by: 'slide',
					},
					on: {
						progress: function () {
							var swiper = this;
							for (var i = 0; i < swiper.slides.length; i++) {
								var slideProgress = swiper.slides[i].progress;
								var innerOffset = swiper.width * interleaveOffset;
								var innerTranslate = slideProgress * innerOffset;
								swiper.slides[i].querySelector(".dual-slider-image__img-wrap").style.transform =
									"translate3d(" + innerTranslate + "px, 0, 0)";
							}
						},
						touchStart: function () {
							var swiper = this;
							for (var i = 0; i < swiper.slides.length; i++) {
								swiper.slides[i].style.transition = "";
							}
						},
						setTransition: function (speed) {
							var swiper = this;
							for (var i = 0; i < swiper.slides.length; i++) {
								swiper.slides[i].style.transition = speed + "ms";
								swiper.slides[i].querySelector(".dual-slider-image__img-wrap").style.transition =
									speed + "ms";
							}
						},
					}
				};
			}

			$this.addClass("instance-" + index);
			$this.parent().find(".swiper-pagination").addClass("pagination-" + index);
			$this.parent().find(".swiper-button-prev").addClass("prev-" + index);
			$this.parent().find(".swiper-button-next").addClass("next-" + index);
			dualImageInstances[index] = new Swiper($this, dualSliderImageParams);
		});

		$(".js-dual-slider-text").each(function (index, element) {
			var $this = $(this);
			$this.addClass("instance-" + index);
			$this.parent().find(".swiper-pagination").addClass("pagination-" + index);
			$this.parent().find(".swiper-button-prev").addClass("prev-" + index);
			$this.parent().find(".swiper-button-next").addClass("next-" + index);
			dualTextInstances[index] = new Swiper($this, {
				direction: 'horizontal',
				spaceBetween: 0,
				speed: 1500,
				loop: true,
				watchSlidesProgress: true,
				slidesPerView: 1,
				effect: 'slide',
				autoplay: {
					delay: 5000
				},
				controller: {
					by: 'slide',
				},
				navigation: {
					prevEl: $this.parent().find(".swiper-button-prev")[0],
					nextEl: $this.parent().find(".swiper-button-next")[0]
				},
				pagination: {
					el: $this.find(".swiper-pagination")[0],
					type: 'bullets',
					clickable: true,
					hideOnClick: false
				},
				on: {
					init: function () {
						//$this.parent().find('.dual-slider__quantity').text($this.find('.dual-slider-text__item').length);
					},
					transitionStart: function () {
						$this.find('.swiper-slide-active').find('.dual-slider-text__counter').html(this.realIndex + 1);
					},
				}
			});
		});

		$(".js-dual-slider-image").each(function (index, element) {
			var $swiper = this.swiper;
			$swiper.controller.control = dualTextInstances[index];
		});

		$(".js-dual-slider-text").each(function (index, element) {
			var $swiper = this.swiper;
			$swiper.controller.control = dualImageInstances[index];
		});
	}

	// Image slider

	var imageSliderInstances = [];

	function imageSliderInit() {

		if ($('.js-image-slider').length) {

			$(".js-image-slider").each(function (index, element) {
				var $this = $(this);
				var $imageSliderAutoHeight,
						$imageSliderEffect;
				$this.addClass("instance-" + index);

				if ($this.hasClass('image-slider--news')) {
					$imageSliderAutoHeight = true;
					$imageSliderEffect = 'fade';
				} else {
					$imageSliderAutoHeight = false;
					$imageSliderEffect = 'slide';
				}

				var imageSliderParams = {
					direction: 'horizontal',
					spaceBetween: 0,
					slidesPerView: 1,
					effect: $imageSliderEffect,
					autoHeight: $imageSliderAutoHeight,
					loop: true,
					watchOverflow: true,
					navigation: {
						prevEl: $this.parent().find(".swiper-button-prev")[0],
						nextEl: $this.parent().find(".swiper-button-next")[0]
					}
				};

				imageSliderInstances[index] = new Swiper($this, imageSliderParams);
			});
		}
	}

	imageSliderInit();

	// Gallery slider

	var gallerySliderInstances = [];

	function gallerySliderInit() {

		if ($('.js-gallery-slider').length) {

			$(".js-gallery-slider").each(function (index, element) {
				var $this = $(this);
				$this.addClass("instance-" + index);
				gallerySliderInstances[index] = new Swiper($this, {
					direction: 'horizontal',
					spaceBetween: 0,
					slidesPerView: 1,
					watchOverflow: true,
					navigation: {
						prevEl: $this.parent().find(".swiper-button-prev")[0],
						nextEl: $this.parent().find(".swiper-button-next")[0]
					},
					breakpoints: {
						768: {
							slidesPerView: 3,
							spaceBetween: 15
						},
						1024: {
							slidesPerView: 3,
							spaceBetween: 21
						},
						1440: {
							slidesPerView: 3,
							spaceBetween: 21

						},
						1700: {
							slidesPerView: 3,
							spaceBetween: 59
						}
					}
				});
			});
		}
	}

	gallerySliderInit();

	// Steps slider

	var stepsSliderInstances = [];

	function stepsSliderInit() {

		if ($('.js-steps-slider').length) {

			$(".js-steps-slider").each(function (index, element) {
				var $this = $(this);
				$this.addClass("instance-" + index);
				stepsSliderInstances[index] = new Swiper($this, {
					direction: 'horizontal',
					spaceBetween: 0,
					slidesPerView: 1,
					watchOverflow: true,
					navigation: {
						prevEl: $this.parent().find(".swiper-button-prev")[0],
						nextEl: $this.parent().find(".swiper-button-next")[0]
					},
					breakpoints: {
						768: {
							slidesPerView: 'auto',
							spaceBetween: 0
						},
						/*						1024: {
													slidesPerView: 5,
													spaceBetween: 0
												},
												1440: {
													slidesPerView: 6,
													spaceBetween: 0
												},
												1700: {

												}*/
					}
				});
			});
		}
	}

	stepsSliderInit();

	// ACCORDION

	function scrollToAnchor(aid) {
		var $pageHeaderHeight = $('.page-header').height();
		$('html, body').animate({scrollTop: aid.offset().top - $pageHeaderHeight},'slow');
	}

	$(".js-accordion .accordion__title").on("click", function (e) {
		e.preventDefault();
		var $this = $(this),
			$list = $this.closest(".accordion__list"),
			$item = $this.closest(".accordion__item"),
			$content = $item.find(".accordion__sub-list"),
			$allContent = $list.find(".accordion__sub-list"),
			$duration = 300;
		if (!$item.hasClass("accordion__item--active")) {
			$item.siblings().removeClass("accordion__item--active");
			$item.addClass("accordion__item--active");
			$allContent.stop().slideUp($duration);
			$content.stop().slideToggle($duration, function () {
				scrollToAnchor($this);
			});
		} else {
			$content.stop().slideUp($duration);
			$item.removeClass("accordion__item--active");
		}
	});

	// FILTER

	$(function () {
		var $input = $("input[name='keyword']"),
				$context = $(".price-list__row");
		$input.on("input", function () {

			var term = $(this).val();
			$context.show().unmark();

			if (term) {
				$context.mark(term, {
					done: function () {
						$context.not(":has(mark)").hide();
					}
				});
			}
		});
	});

	// MAP

	if ($("#map").length > 0) {
		initYandexMap();
	}

	// SELECT 2

	var customSelectOptions = {}

	$('.js-form-select').each(function () {
		var $this = $(this);
		$placeholder = $(this).data('placeholder');

		if ($this.hasClass('form-select--theme_dark')) {
			customSelectOptions = {
				placeholder: $placeholder,
				containerCssClass: 'custom-select--theme_dark',
				dropdownCssClass: 'custom-dropdown--theme_dark',
				minimumResultsForSearch: Infinity,
				width: '100%'
			}
		} else {
			customSelectOptions = {
				placeholder: $placeholder,
				containerCssClass: 'custom-select',
				dropdownCssClass: 'custom-dropdown',
				minimumResultsForSearch: Infinity,
				width: '100%'
			}
		}

		$this.select2(customSelectOptions);
	});

	$('.js-multiple-select').select2({
		width: '100%',
		containerCssClass: 'multiple-custom-select',
		dropdownCssClass: 'multiple-custom-dropdown',
		closeOnSelect: false,
		minimumResultsForSearch: Infinity,
		dropdownParent: $('#modal')
	});

	$('.select2-search__field').css('width', '100%');

	$('.js-multiple-select').on('select2:opening select2:closing', function( event ) {
		var $searchfield = $( '#'+event.target.id ).parent().find('.select2-search__field');
		$searchfield.prop('disabled', true);
	});

	// TABS

	$("ul.js-tabs li").on('click', function () {
		var tab_id = $(this).attr("data-tab");
		$(this).closest("ul.js-tabs").children("li").removeClass("tabs__item--current");
		$(this).closest(".tabs").children(".tabs__content").removeClass("tabs__content--current");
		$(this).addClass("tabs__item--current");
		$("#" + tab_id).addClass("tabs__content--current");

		if ($(this).closest('.tabs').hasClass('tabs--img-control')) {
			$(".main-banner__img").removeClass("main-banner__img--current");
			$("#img-" + tab_id).addClass("main-banner__img--current");

			if ($('.main-banner__img--current').hasClass('is-interactive')) {
				$('.main-banner').addClass('main-banner--interactive');
			} else {
				$('.main-banner').removeClass('main-banner--interactive');
			}

			if ($(".main-banner__title").length) {
				$(".main-banner__title").removeClass("main-banner__title--current");
				$("#title-" + tab_id).addClass("main-banner__title--current");
			}
		}
		sliderUpadate();
	});

	$(".js-tabs-filter").change(function () {
		var choice = $(this).val();
		var filter = $(this);
		filter.closest(".tabs").find(".tabs__item").removeClass("tabs__item--current");
		filter.closest(".tabs").children(".tabs__content").removeClass("tabs__content--current");
		filter.closest(".tabs").children("#" + choice).addClass("tabs__content--current");

		if ($(this).closest('.tabs').hasClass('tabs--img-control')) {
			$(".main-banner__img").removeClass("main-banner__img--current");
			$("#img-" + choice).addClass("main-banner__img--current");

			if ($('.main-banner__img--current').hasClass('is-interactive')) {
				$('.main-banner').addClass('main-banner--interactive');
			} else {
				$('.main-banner').removeClass('main-banner--interactive');
			}

			if ($(".main-banner__title").length) {
				$(".main-banner__title").removeClass("main-banner__title--current");
				$("#title-" + choice).addClass("main-banner__title--current");
			}
		}
		sliderUpadate();
	});

	var getUrlParameter = function getUrlParameter(sParam) {
		var sPageURL = window.location.search.substring(1),
			sURLVariables = sPageURL.split('&'),
			sParameterName,
			i;

		for (i = 0; i < sURLVariables.length; i++) {
			sParameterName = sURLVariables[i].split('=');

			if (sParameterName[0] === sParam) {
				return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
			}
		}
	};

	var tabParam = getUrlParameter('tab');

	if (tabParam != undefined) {
		var tabTarget = (parseInt(tabParam) - 1);
		console.log(tabTarget);

		$(".service-menu__tabs li")[tabTarget].click();
	}

	// FILTER

	$('.js-mobile-filter-toggle').on('click', function() {
		var $this	= $(this);

		$this.toggleClass('is-opened');

		if ($this.hasClass('is-opened')) {
			$('.p-mobile-filter__list').fadeIn(300);
		} else {
			$('.p-mobile-filter__list').fadeOut(300);
		}
	});

	$('.js-p-mobile-filter-toggle').on('click', function() {
		var $this = $(this);
		var $thisText = $this.text();

		$this.closest('.p-mobile-filter').find('.p-mobile-filter__title').text($thisText);
		$this.closest('.p-mobile-filter__list').fadeOut(300);
		$this.closest('.p-mobile-filter').find('.p-mobile-filter__toggle').removeClass('is-opened');

	});

	$('.js-filter-toggle').on('click', function() {
		var $this = $(this);

		$this.toggleClass('p-filter__link--active');
		$this.parent().siblings().find('.p-filter__link').removeClass('p-filter__link--active');

	});

	// FANCYBOX

	var scrollBarWidth = getScrollbarWidth();

	$('.fancy-form').fancybox({
		baseTpl:
			'<div class="fancybox-container modal-form" role="dialog" tabindex="-1">' +
			'<div class="fancybox-bg"></div>' +
			'<div class="fancybox-inner">' +
			'<div class="fancybox-infobar"><span data-fancybox-index></span>&nbsp;/&nbsp;<span data-fancybox-count></span></div>' +
			'<div class="fancybox-toolbar">{{buttons}}</div>' +
			'<div class="fancybox-navigation">{{arrows}}</div>' +
			'<div class="fancybox-stage"></div>' +
			'<div class="fancybox-caption"><div class=""fancybox-caption__body"></div></div>' +
			'</div>' +
			'</div>',
		defaultType: 'inline',
		autoFocus: false,
		touch: false,
		clickSlide: false,
		trapFocus: true,
		toolbar   : false,
		smallBtn: false,

		beforeShow: function () {
			if ($('.page-header').hasClass('page-header--fixed')) {
				$('.page-header').css('padding-right', scrollBarWidth + 'px');
			}
		},
		afterClose: function () {

			if ($('.page-header').hasClass('page-header--fixed')) {
				$('.page-header').css('padding-right', '0');
			}
		}
	});

	$('.js-modal-close').on('click', function() {
		$.fancybox.close();
	});

	// INPUT FILE TYPE

	$('.js-input-file').each(function() {
		var $input = $(this),
			$label = $input.next('.js-file-label'),
			$labelVal = $label.html();

		$input.on('change', function(element) {
			var fileName = '';
			if (element.target.value) fileName = element.target.value.split('\\').pop();
			fileName ? $label.addClass('has-file').find('.js-file-name').html(fileName) : $label.removeClass('has-file').html($labelVal);
		});
	});

	// VALIDATION

	var validationParams = {
		errorClass: 'invalid',
		validClass: 'success',
		ignore: ':hidden:not(:checkbox)',
		errorPlacement: function(error, element){},
		highlight: function( element, errorClass, validClass ) {
			$(element).addClass(errorClass).removeClass(validClass);
			$(element).closest(".js-form-select").parent().find('.form-select').addClass(errorClass).removeClass(validClass);
		},
		unhighlight: function( element, errorClass, validClass ) {
			$(element).removeClass(errorClass).addClass(validClass);
			$(element).closest(".js-form-select").parent().find('.form-select').removeClass(errorClass).addClass(validClass);
		},
	};

	$(".js-order-form").each(function () {
		$(this).validate(validationParams);
	});

	// Interactive schemes

	function randomInteger(min, max) {
		let rand = min + Math.random() * (max + 1 - min);
		return Math.floor(rand);
	}

	$('.js-area-btn-toggle').on('click', function() {
		var $this = $(this);
		var $wWidth = $(window).outerWidth();

		if ($wWidth > 1279) {
			$this.toggleClass('area__btn--active');
			if ($this.hasClass('area__btn--active')) {
				$this.parent().find('.area__tooltip').fadeIn(300);
				$this.parent().siblings().find('.area__btn').removeClass('area__btn--active');
				$this.parent().siblings().find('.area__tooltip').fadeOut(300);
			} else {
				$this.parent().find('.area__tooltip').fadeOut(300);
			}
		}
	});

	$('.js-area-tooltip-close').on('click', function () {
		var $this = $(this);
		$this.parent().fadeOut(300);
		$this.closest('.area__item').find('.area__btn').removeClass('area__btn--active');
	});

	// TOP BUILDING IMAGE

	$('.js-building-toggle').on('mouseover', function () {
		var $this = $(this);
		var $wWidth = $(window).outerWidth();

		function delay(i) {
			setTimeout(function () {
				$('.building__img-top .building-segment').eq(i).css({fill: 'transparent', transition: 'fill 0.5s'});
			}, randomInteger(250, 1250));
		}

		if ($wWidth > 1279 && !$this.hasClass('is-animated')) {
			$('.building-segment').each(function(i) {
				delay(i);
			});

			$this.css({'pointer-events': 'none'});
			$this.addClass('is-animated');
		}
	});

/*	$('.js-building-layer-toggle').on('mouseleave', function () {
		var $length = $('.building__img-top .building-segment').length;
		var $wWidth = $(window).outerWidth();

		if ($wWidth > 1279) {

			for (var i = 0; i < $length; i++) {

				(function (i) {
					setTimeout(function () {
						$('.building__img-top .building-segment').eq(i).removeAttr('style');
					}, randomInteger(100, 1000));
				}(i));
			}

			$('.building__tooltip').fadeOut(300);
			$('.building-segment-bottom').removeClass('is-active');

			$('.js-building-toggle').removeAttr('style');

		}

	});*/

	// BOTTOM BUILDING IMAGE

	$('.building-segment-bottom').on('click', function() {
		var $this = $(this);
		var $thisId = $(this).attr('data-segment');
		var $wWidth = $(window).outerWidth();

		if ($wWidth > 1279) {
			$this.toggleClass('is-active');

			if ($this.hasClass('is-active')) {
				$this.siblings().removeClass('is-active');
				$('#' + $thisId).find('.building__tooltip').fadeIn(300);
				$('#' + $thisId).siblings().find('.building__tooltip').fadeOut(300);
			} else {
				$('#' + $thisId).find('.building__tooltip').fadeOut(300);
				$this.removeClass('is-active');
			}
		}
	});

	$('.js-building-tooltip-close').on('click', function () {
		var $this = $(this);
		$this.parent().fadeOut(300);
		$this.closest('.building__item').find('.building__btn').removeClass('building__btn--active');
	});

	// RESIZE TIMEOUT

	var resizeTimer;

	$(window).on('resize', function (e) {

		clearTimeout(resizeTimer);
		resizeTimer = setTimeout(function () {

			// check sliders
			structureSliderInit();
			mobileSliderInit();
		}, 250);
	});

});